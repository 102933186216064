/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Portfolio main     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.portfolio-banner {
  margin-top: 70px;

  @include mobile {
    margin-top: 87px;
  }

  @include mobile-lg {
    margin-top: 97px;
  }

  @include desktops {
    margin-top: 145px;
  }

  @include large-desktops {
    margin-top: 100px;
  }

  .portfolio-banner-content {
    padding: 40px 0px;

    @include tablet {
      padding: 60px 0px;
    }

    h2 {
      color: $haiti;
      font-size: 36px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: -1.98px;
      line-height: normal;

      @include mobile-lg {
        font-size: 60px;
      }

      @include tablet {
        font-size: 48px;
      }

      @include desktops {
        font-size: 60px;
      }

    }

    p {
      color: $waterloo;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: -0.66px;
      line-height: 34px;
    }



  }

}

.portfolio-banner-content--2 {
  padding: 0 !important;
}

.portfolio-details-banner--2 {
  padding-top: 60px;

  @include tablet {
    padding-top: 80px;
  }

  @include desktops {
    padding-top: 120px;
  }

}

.portfolio-card-masonry {
  position: relative;
}

.portfolio-navbar {
  padding: 30px 0px;

  @include tablet {
    padding: 50px 0px;
  }

  ul {
    li {
      a {
        color: #6f727b;
        font-size: 13px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 1.63px;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}

.link-hover-light:hover {
  color: #495fef;
}

.isotope-nav li a.active {
  color: #495fef;
}

.portfolio-card-masonry:hover .text-block {
  bottom: 20px;
  opacity: 1;
}

.portfolio-card-masonry .text-block {
  position: absolute;
  bottom: 0px;
  left: 20px;
  right: 20px;
  opacity: 0;
  transition: .4s;
  padding: 30px 15px 15px 30px;
}

.nogap-btn-margin {
  margin-top: 45px;
}

.portfolio-card-hover .card-image {
  transition: .4s;
}

.portfolio-card-hover .card-image img {
  transition: .4s;
}

.portfolio-card-hover:hover .card-image {
  transform: scale(0.8) rotate(-16deg);
}

.portfolio-card-hover:hover img {
  border-radius: 20px;
  box-shadow: 0 32px 74px rgba(68, 77, 136, 0.2);
}



.link-hover-reset:hover {
  color: var(--color-text);
}

.link-hover-rotate {
  transition: .4s;
  display: inline-flex;
  will-change: transform;
  transform: rotate(0deg);
}

.link-hover-rotate:hover {
  transform: rotate(-10deg);
}


.isotope-item {
  transition: .4s;
}

.gr-bg-opacity {
  z-index: 1;
}

.gr-bg-opacity:before {
  position: absolute;
  content: "";
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: .9;
  border-radius: 8px;
}

.gr-pb-7,
.gr-py-7 {
  padding-bottom: 25px !important;
}


.portfolio-card-masonry {

  span {
    color: #161c2d;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.63px;
    line-height: normal;
    opacity: 0.5;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  h3 {
    color: #161c2d;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -1.2px;
    line-height: 44px;

    @include tablet {
      font-size: 26px;
    }
  }
}

.portfolio-card-masonry-1 {
  a {
    text-decoration: none;
  }
}

.content-area-grid-1 {

  span {
    color: #161c2d;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.63px;
    line-height: normal;
    opacity: 0.5;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  h3 {
    color: #161c2d;
    font-size: 32px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -1.2px;
    line-height: 44px;
  }
}

.content-area-grid-1 {
  padding: 30px 0px 40px;

  @include tablet {
    padding: 47px 0px 68px;
  }

}

.portfolio-load-btn {
  .btn {
    min-width: 225px;
    height: 60px;
    border-radius: 33px;
    border: 1px solid #030303;
    opacity: 0.3;
    color: #030303;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.63px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      color: $haiti !important;
      opacity: 1;
    }
  }
}

.grid--no-gap {
  .col-md-6 {
    padding: 0;
  }

  .col-sm-6 {
    padding: 0;
  }

  .col-lg-4 {
    padding: 0;
  }

  .col-lg-3 {
    padding: 0;
  }

  .col-12 {
    padding: 0;
  }
}


.portfolio-details-banner {
  margin-top: 70px;
  padding-bottom: 60px;

  @include mobile {
    margin-top: 87px;
  }

  @include mobile-lg {
    margin-top: 97px;
  }

  @include tablet {
    padding-bottom: 80px;
  }

  @include desktops {
    margin-top: 145px;
    padding-bottom: 120px;
  }

  @include large-desktops {
    margin-top: 100px;

  }

  .portfolio-banner-content {
    padding: 40px 0px;

    @include tablet {
      padding: 60px 0px;
    }

    h2 {
      color: $white;
      font-size: 36px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: -1.98px;
      line-height: normal;

      @include mobile-lg {
        font-size: 60px;
      }

      @include tablet {
        font-size: 48px;
      }

      @include desktops {
        font-size: 60px;
      }

    }

    p {
      color: $porcelain;
      font-size: 20px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: -0.66px;
      line-height: 34px;
      opacity: 0.5;
    }
  }

}

.portfolio-details-banner-card {
  border-radius: 8px;
  background-color: $white;
  padding: 30px 30px 30px 30px;
  margin-top: 15px;
  @include tablet {
    padding: 50px 45px 50px 45px;
    margin-top: 35px;
  }

  @include desktops {
    margin-top: 0;
  }

  .card-all-items {
    margin-bottom: -30px;
  }

  .card-item {
    margin-right: 15px;
    margin-bottom: 30px;

    span {
      opacity: 0.5;
      color: #161c2d;
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      margin-right: 15px;
      min-width: 35%;
      @include mobile-lg {
        min-width: 25%;
      }
    
      @include tablet {
        min-width: 20%;
      }
    }

    strong {
      color: #161c2d;
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: 17px;
    }
  }

}

.portfolio--details-section {
  padding-top: 60px;

  @include tablet {
    padding-top: 80px;
  }

  @include desktops {
    padding-top: 115px;
  }
}

.portfolio-single-items {
  margin-bottom: -25px;

  .col-lg-12 {
    margin-bottom: 25px;
  }

  .col-lg-6 {
    margin-bottom: 25px;
  }
}

.pre-next-project-link-area {
  padding: 50px 0px;
  margin-bottom: -20px;

  .project-m {
    margin-bottom: 20px;
  }

  @include tablet {
    padding: 70px 0px;
  }

  .icon {
    margin-right: 15px;

    i {
      color: #262626;
      font-size: 13px;
      width: 21px;
      height: 21px;
      line-height: 19px;
      border-radius: 50%;
      text-align: center;
      border: 2px solid #262626;
    }

  }

  a {
    opacity: 0.5;
    color: #161c2d;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.63px;
    line-height: normal;
    text-align: left;
    text-transform: uppercase;
    text-decoration: none;
  }

  .next-project {
    .icon {
      margin-left: 15px;
      margin-right: 0;
    }
  }

  .project-page-grid {
    i {
      font-size: 20px;
    }
  }
}

.related-works-area {
  padding-top: 50px;
  padding-bottom: 50px;

  @include tablet {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @include desktops {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .related-works-title {
    margin-bottom: 38px;

    @include tablet {
      margin-bottom: 58px;
    }

    @include desktops {
      margin-bottom: 68px;
    }

    h2 {
      color: #161c2d;
      font-size: 36px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: -1.2px;
      line-height: 48px;
    }
  }
}

.related-works-area-items {
  margin-bottom: -30px;

  .col-xl-3 {
    margin-bottom: 30px;
  }
}

.related-work-content {
  margin-top: 32px;

  span {
    display: inline-block;
    color: #161c2d;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.63px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 13px;

    a {
      opacity: 0.5;
      color: #161c2d;
      text-decoration: none;
    }
  }

  strong {
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: -0.5px;
    line-height: 34px;
    display: inherit;

    a {
      color: #161c2d;
      text-decoration: none;
    }
  }

}

.pt-90{
  padding-top: 45px;
  @include tablet {
    padding-top: 60px;
  }

  @include desktops {
    padding-top: 90px;
  }
}